import { ReferenceLink } from "helpers/reference"
import { FooterLinkProps } from "types/footer"
import Typography from "../typography"

const FooterLinks: React.FC<FooterLinkProps> = ({ footerLinks }) => {
  return (
    <div className="text-green-dark flex flex-col gap-y-4 gap-x-10 text-xs lg:flex-row lg:text-sm">
      {footerLinks && footerLinks.map((footerLink) => {
        return (
          <ReferenceLink key={footerLink.label} target={footerLink.reference}>
            <Typography>{footerLink.label}</Typography>
          </ReferenceLink>
        )
      })}
    </div>
  )
}

export default FooterLinks
