import { ContactMailProps } from "types/footer"

const ContactEmail: React.FC<ContactMailProps> = ({ contactLabel, contactMail }) => {
  return (
    <div className="text-xs lg:text-lg tracking-wide text-green-dark">
      <div>{contactLabel}</div>
      <a className='mt-3 underline' title="email" href={`mailto:${contactMail}`} target="_self">{contactMail}</a>
    </div>
  )
}

export default ContactEmail
