import React from 'react'
import { GLOBAL_SITE } from 'helpers/constants/envVariables'
import { getMappedLocale } from 'helpers/localeHelpers'
import { FooterProps } from 'types/footer'
import Image from 'frontastic/lib/image'
import ContactEmail from './contact-email'
import FooterLinks from './footer-links'

const Footer: React.FC<FooterProps> = ({ contactLabel, contactMail, footerLinks, btmRightLogo, globalFooterLinks }) => {
  return (
    <>
      <footer
        className="footer-nerivio min-h-footer-sm lg:min-h-footer-lg flex flex-col justify-end bg-gradient-footer p-space-inside-header-footer-sm lg:px-space-inside-header-footer-lg"
        aria-label="footer"
      >
        <div className="flex flex-col justify-between gap-4 lg:gap-20">
          {GLOBAL_SITE !== 'true' ? (
            <>
              <ContactEmail contactLabel={contactLabel} contactMail={contactMail} />
              <div className="flex w-full items-center justify-between md:w-full lg:mt-0">
                <FooterLinks footerLinks={footerLinks} />
                <div className="aspect-betapharm-logo mt-auto w-1/2 lg:w-[280px]">
                  <Image
                    media={btmRightLogo}
                    alt={btmRightLogo?.['title']?.[getMappedLocale()] ?? ''}
                    objectFit="contain"
                    loading={'lazy'}
                    sizes="(max-width: 1023px) 50vw, 280px"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="flex w-full items-center justify-between md:w-full lg:mt-0">
              <FooterLinks footerLinks={globalFooterLinks} />
            </div>
          )}
        </div>
      </footer>
    </>
  )
}

export default Footer
